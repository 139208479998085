.custom-h-textarea .form-control {
    height: 175px !important;
}


.css-1nwzg8w-JoyButton-root {
    justify-content: flex-start !important;
    border: none !important;
    width: 100% !important;
    display: flex !important;
}

.hidden-content-3-line {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.h-content-history {
    height: calc(100vh - 240px) !important;
}

.custom-show-img-feedback {
    height: 80px;
    width: 80px;
    border-radius: 8px;
    border: 1px solid #d5d5d5;
    /* overflow: hidden; */
    flex-shrink: 0;
    position: relative;
}

.custom-input-upload-img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 0;
    visibility: hidden;
}

.btn-delete-img-choose {
    height: 25px;
    width: 25px;
    position: absolute;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    right: -10px;
    top: -10px;
    font: 600;
    font-size: 20px;
    color: white;
}

.custom-show-img-feedback img {
    border-radius: 8px;
}

.mb-6 {
    margin-bottom: 24px;
}