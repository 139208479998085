.chair-in-table {
    width: 18px;
    height: 6px;
    border-radius: 12px;
    border: 1px;
    background-color: #FFFFFF;
}

.circle-name-table {
    height: 50%;
    width: 50%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
}

.aspect-square {
    aspect-ratio: 1 / 1;
}

.table-pos {
    padding: 16px;
    background: #FFFFFF;
    cursor: pointer;
    position: relative;

}

.paid {
    background-color: #E7F6ED;
    color: #0FA54A;
}

.reserved {
    background-color: #FFF3E8;
    color: #FE841B;
}

.available {
    background-color: #EEF6FE;
    color: #269AFF;
}

.pb-130px {
    padding-bottom: 130px;
}

.text-name-table {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0px;
}

.border-1px-FF7B2C {
    border: 1px solid #FF7B2C;
}


.w-125px {
    width: 125px;
}

.cusrsor-move {
    cursor: move;
}

.border-12px {
    border-radius: 12px;
}

.border-circle {
    border-radius: 50%;
}