.box-notification-router {
  width: 100%;
  /* height: 40px; */
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-box-notification-router {
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0;
}

.notification-staff-name{
  color: white;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0;
}