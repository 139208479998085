.h-140px {
    height: 140px;
}

.w-140px {
    width: 140px;
}

.text-start {
    text-align: start;
}

.text-end {
    text-align: end;
}

.aspect-ratio-1 {
    aspect-ratio: 1/1;
}

.border-top-dasher {
    border-top: 1px dashed rgb(199, 199, 199);
}

.bg-red {
    background-color: rgb(246, 107, 107);
}

.width-50px {
    width: 50px !important;
}

.height-50px {
    width: 50px !important;
}

.modal-alert .modal-dialog.modal-dialog-centered .modal-content {
    width: 80% !important;
}

.modal-alert .modal-dialog.bg-gray-light .modal-content {
    width: 100% !important;
}

.modal-dialog-centered {
    justify-content: center;
}

.modal-checked-success .modal-dialog .modal-content {
    border-radius: 20px;
}