.custom-card-order-food {
    width: 100%;
    padding: 10px 20px;
}

.visible-hidden {
    visibility: hidden;
}

.order-food-items {
    border: 1px solid #DCDCE4;
    border-radius: 16px;
    padding: 10px 12px;

}

.status-items-order {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 30px; */
    padding: 4px 8px 4px 8px;
    border-radius: 8px;
    background-color: #FFF2EA;
    gap: 5px;
}

.text-items-order {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    color: #FF7B2C;
}

.btn-number-order {
    background-color: #FFF2EA;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.gap-8px {
    gap: 8px;
}

.text-number-order {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #666687;
}

.text-more-request {
    width: 100%;
    height: 82px;
    padding: 12px 16px 12px 16px;
    border-radius: 16px;
    border: 1px solid #DCDCE4;
    gap: 10px
}

.text-more-request:focus {
    outline: none;
}

.choose-a-side {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
    margin-top: 16px;
}