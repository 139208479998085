.modal-add-printer .modal-dialog .modal-content {
  border-radius: 22px;
}

.box-input-add-printer-name {
  border: 1px solid rgb(193, 193, 194);
  border-radius: 12px;
  overflow: hidden;
}

.bg-delete {
  background-color: rgb(252, 40, 40);
}

.input-enter-printer-name::placeholder {}