.h-body-table-salary {
    display: table-caption;
    height: calc(100vh - 200px) !important;
    overflow-y: scroll;
    width: 100%;
}

.h-body-table-staff-review {
    display: table-caption;
    height: calc(100vh - 517px) !important;
    overflow-y: auto;
    width: 100%;
    overflow: -moz-scrollbars-none !important;
    -ms-overflow-style: none !important;
}

.h-body-table-staff-review::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
}

.py-40px {
    padding-top: 40px;
    padding-bottom: 40px;
}

.px-100px {
    padding-right: 100px;
    padding-left: 100px;
}

.btn-salary-weeek {
    width: 100%;
    background-color: #12B064;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: justified;
    color: white;
}

.btn-salary-weeek:nth-child(2) {
    background-color: #34e2ee;
}

.btn-salary-weeek:nth-child(3) {
    background-color: #009996;
}

.box-salary-for-employee {
    width: 100%;
    padding-top: 70px;
    height: 100vh;

}

.header-salary-for-employee {
    height: 70px;
    /* position: fixed; */
    width: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    background-color: #12B064;
    align-items: center;
}

.text-title-salary-week {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: justified;
    color: white;
    margin-bottom: 0;
}

.btn-back-salary-week {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: justified;
    color: rgb(82, 82, 82);
    margin-bottom: 0;
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background-color: white;
}

.btn-back-salary-week:nth-child(2) {
    opacity: 0;
}

.box-container-salary-for-element {
    display: flex;
    justify-content: center;
}

.container-salary-for-element {
    width: 500px;
    padding-top: 16px;
    height: calc(100vh - 140px);
    padding-bottom: 16px;
    overflow-y: scroll;
}

.calculate-Salary-box {
    border-top: 1px solid;
    border-bottom: 1px solid;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: justified;
    display: flex;
    padding: 10px;
    justify-content: center;
    gap: 10px;
}

.salary-table {
    height: 100vh;
    padding-top: 70px;
}

.btn-print-salary-staff-week {
    padding: 6px 10px;
    border-radius: 8px;
    background-color: #12B064;
    color: white;
    cursor: pointer;
}

.h-100vh-158px {
    max-height: calc(100vh - 158px);
    overflow-y: scroll;
}

.h-body-table-employee {
    display: table-caption;
    height: calc(100vh - 334px) !important;
    overflow-y: scroll;
    width: 100%;
}
.w-500px{
    width: 500px;
}
.text-popover-tip{
    font-size: 14px;
    font-family: 600;
    color: #4a4a6a;
    margin: 0;
}