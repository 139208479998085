.custom-w-edit .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1pw81iq-MuiInputBase-root-MuiFilledInput-root {
    width: 120px !important;
}

.css-1237l27-MuiFormControl-root-MuiTextField-root .MuiFilledInput-root {
    overflow: hidden;
    border-radius: 8px !important;
    background-color: white !important;
    border: 1px dashed !important;
    border-color: #E0E3E7;
    -webkit-transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.css-1237l27-MuiFormControl-root-MuiTextField-root .MuiFilledInput-root.Mui-focused {
    background-color: transparent;
    box-shadow: unset !important;
    border: 1px solid #32324D !important;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
    /* padding: unset !important; */
    height: unset !important;
}

.t-body-salary-detail {
    display: table-caption;
    height: calc(100vh - 300px) !important;
    overflow-y: scroll !important;
    width: 100%;
}

.custom-input .css-10botns-MuiInputBase-input-MuiFilledInput-input {
    padding-top: 12px !important;
}

.custom-w-drawer-sumary .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorRight.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 37%;
}

.box-shadow-print {
    box-shadow: 0px 4px 120px 0px #24242414;

}

.date-picker-clockin,
.date-picker-clockout {
    min-width: 140px !important;
    width: 140px;
}

.date-picker-clockin fieldset {
    border: 1px dashed !important;
}

.date-picker-clockout fieldset {
    border: 1px dashed !important;
}

.date-picker-clockout .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root input#mui-4,
.date-picker-clockin .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root input#mui-2 {
    /* pointer-events: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    width: fit-content; */
    padding: 10px 0px 10px 12px;
}

.MuiStack-root.css-a643pv-MuiStack-root {
    padding: 0;
}

.w-280px {
    width: 280px;
}

.width-drawer-1000 {
    width: 1000px;
}