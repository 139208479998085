table.table-group-customer {
  border-collapse: collapse;
  border: 0;
}
table.table-group-customer th,
table.table-group-customer td {
  /* border: 1px solid #aaa; */
  background-clip: padding-box;
  scroll-snap-align: start;
  border: 0;
}
table.table-group-customer tbody tr:last-child th,
table.table-group-customer tbody tr:last-child td {
  border: 0;
}
table.table-group-customer thead {
  z-index: 1000;
  position: relative;
}
table.table-group-customer th,
table.table-group-customer td {
  padding: 0.6rem;
  min-width: 6rem;
  text-align: left;
  margin: 0;
}
table.table-group-customer thead th {
  position: sticky;
  top: 0;
  border-top: 0;
  background-clip: padding-box;
  background-color: green !important;
  color: white;
}
table.table-group-customer thead th.pin {
  left: 0;
  z-index: 99999;
  border: 0;
}
.number-length-table {
  left: 0;
  z-index: 99999;
  border: 0;
}
table.table-group-customer thead th.header-customer-name {
  left: 90px;
  z-index: 300;
  border: 0;
}
table.table-group-customer tbody th {
  background-clip: padding-box;
  border: 0;
}
table.table-group-customer tbody {
  z-index: 10;
  position: relative;
}
table.table-group-customer tbody th {
  position: sticky;
  /* left: 0; */
  z-index: 999;
}
.th-sticky-tbody-0 {
  left: 0;
}
.th-sticky-tbody-1 {
  left: 90px;
}
table.table-group-customer thead th,
table.table-group-customer tbody th {
  background-color: #f8f8f8;
}
.box-table-group-customer {
  margin-top: 12px;
  overflow: scroll;
  /* scroll-snap-type: both mandatory; */
  height: calc(100vh - 450px);
}
.table.table-group-customer tr {
  border-bottom: 1px solid #999999;
}
.z-index--10 {
  z-index: -10 !important;
}
