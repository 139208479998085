.modal-see-your-order .modal-lg {
    max-width: 300px !important;
}

.modal-see-your-order .modal-dialog.modal-dialog-centered .modal-content {
    width: 100% !important;
    border-radius: 12px;
}

.thanks-see-your-order {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.5px;
    text-align: left;
    color: #000000;
}

.see-your-order-successfull {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #000000;
}

.btn-see-your-order {
    width: 152px;
    height: 42px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    background-color: #0FA54A;
}

.modal-choose-bill-order .modal-lg {
    max-width: 1000px !important;
}

.modal-choose-bill-order .modal-dialog.modal-dialog-centered .modal-content {
    width: 100% !important;
    border-radius: 12px;
    height: 500px;
}
