.modal-notification .modal-dialog.modal-90w {
    min-width: 500px !important;
    max-width: 70% !important;
    padding: 0;
    height: fit-content;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.modal-notification .modal-dialog .modal-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.modal-notification .modal-dialog .modal-content .modal-body {
    padding: 0;
}

.text-notification {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
}

.bg-eeeeee {
    background-color: #eeeeee;
    border-radius: 16px;
}

.toast-container-notification {
    border-radius: 16px;
    overflow: hidden;
    position: fixed !important;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 0;
    margin: auto;
}