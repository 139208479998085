.h-107px {
    height: 107px;
}

.max-h-130px {
    max-height: 130px;
}

.w-300px {
    width: 300px;

}

.overflow-y-scroll {
    overflow-y: scroll !important;
}

.text-truncate-container p {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.h-120px {
    height: 120px;
}

.h-130px {
    height: 130px;
}

.h-140px {
    height: 130px;
}

.h-120 {
    height: 150px !important;
}

.h-150 {
    height: 150px !important;
}

.min-h-100px {
    min-height: 100px;
}

.w-700px {
    width: 700px;
}

.title-typeFood {
    font-size: 20px;
    font-weight: 700;
    color: #0571D2;
    white-space: nowrap;
    line-height: normal;
}

.title-noteFood {
    height: fit-content;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 8px;
    background-color: #CDCDCD;
    color: #000;
    border-radius: 8px;
    max-width: 150px;

}

.title-note-option-food {
    height: fit-content;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 8px;
    background-color: #2CA855;
    color: #fff;
    border-radius: 8px;
    max-width: 150px;

}
.decreption-food {
    overflow: auto;
    height: fit-content;
    max-height: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 8px;
    color: #4a4a6a;
    max-width: 150px;

}
.price-food-name-menu {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: #12B064;
}

.box-img-food-name {
    width: 160px;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
}

.bg-12B064 {
    background-color: #12B064;
}

.food-name-menu-items {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
    max-width: 300px;

}

.text-dollar-food-menu-items {
    font-family: Mulish, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
}

.checked-blue .form-check-input:checked {
    background-color: #269AFF;
    border-color: #269AFF;
}

.checked-blue .form-check-input {
    height: 24px;
    width: 42px;
}

.option-tags {
    width: 300px;
}
.box-notefood{
    max-width: 150px;
    overflow: auto;
}

@media only screen and (max-width: 1340px) {
    .option-tags {
        width: 100px !important;
    }
}

@media only screen and (max-width: 1140px) {
    .option-tags {
        width: 50px !important;
    }
}
