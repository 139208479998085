.phone-order-online::placeholder {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #8E8EA9;
}

.phone-order-online {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #8E8EA9;
}

.phone-order-online:focus-visible {
  outline: none;
}

.border-1px-red {
  border: 1px solid red;
}