.w-100px {
    width: 100px;
}

.h-100px {
    height: 100px;
}

.text-right {
    text-align: right;
}

.custum-scroll-food-bill {
    height: 270px;
    overflow-y: scroll;
}
.w-30{
    width: 30%;
}
.border-green-light{
    border: 1px solid #129503 !important;
}
.bg-blue-light{
    background-color: #00A15A  !important;
}
.text-blue-light{
    color: #fdfeff !important;
}
.bg-ffffff{
    background: white;
}
.text-000000{
    color: rgb(30, 34, 37);
}
.w-70px{
    width: 70px;
}