.input-add-visa .form-control {
    padding: 24px 16px 12px 16px !important;
    border-radius: 16px;
    gap: 10px;
    border: 1px solid #EAEAEF;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
    color: white !important;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: white !important;
    opacity: 1;
}

.h-58px {
    height: 58px;
}

.rounded-16px {
    border-radius: 16px;
}

.StripeElement .StripeElement--invalid {
    margin-top: 8px;
}