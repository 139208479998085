.text-content-modal-delete-customer {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.btn-cancel-modal-delete-customer {
  border-radius: 8px;
  width: 48%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid #dee2e6;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
}

.border-delete {
  border: 1px solid #f24343 !important;
}

.text-delete-modal-customer-btn {
  /* //styleName: Subtitle/Subtitle 1; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #f24343;
}

.modal-delete-customer
  .modal-dialog.modal-dialog-centered
  .modal-content:nth-child(1) {
  border-radius: 20px;
  border: 1px solid #d4d4d4;
}

.modal-delete-customer
  .modal-dialog.modal-dialog-centered
  .modal-content
  .modal-dialog
  .modal-content {
  border-radius: 20px;
  border: none;
}

.modal-delete-customer.modal-clock-in-out
  .modal-dialog.modal-dialog-centered
  .modal-content
  .modal-body {
  padding: 0 !important;
}

.h-54px {
  height: 54px;
}

.text-italic {
  font-style: italic;
}

.bg-danger-500 {
  background-color: #f24343 !important;
}

.box-edit-name-table {
  height: 56px;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.input-edit-name-table {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: justified;
  color: #4a4a6a;
}

.input-edit-name-table::placeholder {
  color: #7c7c9c;
}

.btn-save-cancel-floor {
  width: 48%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}
