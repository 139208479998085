.chair-in-table {
    width: 18px;
    height: 6px;
    border-radius: 12px;
    border: 1px;
    background-color: #FFFFFF;
}

.circle-name-table {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-pos {
    padding: 16px;
    background: #FFFFFF;
    cursor: pointer;
    position: relative;
}

.paid {
    background-color: #E7F6ED;
    color: #0FA54A;
}

.reserved {
    background-color: #FFF3E8;
    color: #FE841B;
}

.available {
    background-color: #EEF6FE;
    color: #269AFF;
}

.text-name-table {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0px;
}

.border-1px-FF7B2C {
    border: 1px solid #FF7B2C;
}

.btn-clean-table {
    height: 40px;
    width: 40px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #FE841B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.w-125px {
    width: 125px;
}

.btn-paybill-table {
    height: 40px;
    width: 40px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #f1735d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-paybill-cash-table {
    height: 40px;
    width: 40px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #FE841B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-order-table-reserved {
    height: 40px;
    width: 40px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #0eb713;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-order-table {
    height: 40px;
    width: 40px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #0eb713;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-option-table {
    position: absolute;
    gap: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* top: -40px; */
    right: -45px;
}

.btn-option-order {
    position: absolute;
    gap: 4px;
    display: flex;
    flex-direction: column;
    /* top: -40px; */
    left: -45px;
}

.btn-option-pay-cash {
    position: absolute;
    gap: 4px;
    display: flex;
    flex-direction: column;
    /* bottom: -40px; */
    right: -45px;
}

.z-index-20 {
    z-index: 20;
}

.btn-pos-table-position-top {
    /* top: calc(50% - 40px) !important; */
}