.fade.modal.show {
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-show-side-dish .modal-dialog .modal-content {
  border-radius: 12px;
}

.max-h-40vh {
  max-height: 40vh;
}