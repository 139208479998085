CSS Babel Result Skip Results Iframe EDIT ON * {
    box-sizing: border-box;
}

:root {
    --control: #e69119;
    --color: #fff;
}

.btn-start-game,
.btn-exit-game,
.end-game,
.btn-play-again {
    background: var(--control);
    color: var(--color);
    padding: 1rem 2rem;
    border-radius: 1rem;
    border: 4px solid var(--color);
    font-family: "Fredoka One", cursive;
    font-size: 1.2rem;
}

.body-Whack {
    background: linear-gradient(#d1e9fa 0 40%, #86c270 40%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Fredoka One", cursive;
}

.end-game {
    position: fixed;
    top: 1rem;
    right: 1rem;
}

.mole {
    display: none;
    background: url(https://assets.codepen.io/605876/mole-out-of-hole.svg);
    background-size: cover;
    height: 100%;
    width: 100%;
    border: 0;
}

.mole-hole {
    height: 50px;
    width: 100%;
    overflow: hidden;
    border-bottom: 2vmin solid hsl(35, 50%, 15%);
}

.moles {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 20vmin);
    grid-template-rows: repeat(2, 20vmin);
    align-items: center;
}

.moles>*:nth-of-type(4),
.moles>*:nth-of-type(5) {
    transform: translate(50%, 0);
}

.info {
    position: fixed;
    top: 1rem;
    left: 1rem;
}

.info-text {
    font-size: 2rem;
}

/* h1 {
    font-size: 4rem;
} */

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.body-game {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    margin-top: 40px;
    width: 100vw;
    padding: 0px 40px;
}

.text-title-mole {
    font-size: 48px;
    color: #1b2338;
    font-family: "Fredoka One", cursive !important;
    font-weight: 700;
}