.text-content-modal-delete-customer {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.modal-delete-voucher .modal-dialog .modal-content .modal-dialog {
  width: 100%;
}

.btn-cancel-modal-delete-customer {
  border-radius: 8px;
  width: 48%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid #dee2e6;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
}

.border-delete {
  border: 1px solid #f24343 !important;
}

.text-delete-modal-customer-btn {
  /* //styleName: Subtitle/Subtitle 1; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #f24343;
}

.modal-delete-customer
  .modal-dialog.modal-dialog-centered
  .modal-content:nth-child(1) {
  border-radius: 20px;
  border: 1px solid #d4d4d4;
}

.modal-delete-customer
  .modal-dialog.modal-dialog-centered
  .modal-content
  .modal-dialog
  .modal-content {
  border-radius: 20px;
  border: none;
}

.img-leaf1 {
  position: absolute;
  top: -35px;
  right: -17px;
}

.img-leaf2 {
  position: absolute;
  bottom: -26px;
  left: -17px;
}

.otp-input div div input[type="number"],
.otp-input div div {
  background: #f4f6fa;
  border-radius: 12px;
  width: 120px !important;
  height: 40px;
  border: 1px solid rgb(201, 201, 201);
}

.otp-input div div input:active,
.otp-input div div input:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

.otp-input div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.otp-input div div {
  display: flex;
  justify-content: center;
}

.otp-input div div input {
  border: none;
  background-color: transparent;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #a6a7ab;
}

.otp-input div div input::placeholder {
  color: #a6a7ab;
}

.input-form .native-input {
  height: 100%;
}
.mess-err {
  height: 22px;
  color: #f24343;
  font-style: italic;
  text-align: center;
}
