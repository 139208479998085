.text-select-name-employee {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
    margin-bottom: 24px;
}

.box-select-employee {

    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.h-180px {
    height: 180px;
}

.h-400px {
    height: 400px;
}

.select-employee-items {
    padding: 12px 14px 12px 14px;
    border-radius: 16px;
    gap: 6px;
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #DCDCE4;
}

.select-employee-items-2 {
    padding: 12px 14px 12px 14px;
    border-radius: 16px;
    gap: 6px;
    display: flex;
    align-items: center;
    background-color: #FFB01D;
    border: 1px solid #FFB01D;
}

.text-A5A5BA {
    color: #A5A5BA;
}

.name-ployee-items {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    margin-bottom: 0;
}

.icon-ployee-items {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    margin-bottom: 0;
}

.select-employee-items-confirm {
    padding: 12px 14px 12px 14px;
    border-radius: 16px;
    gap: 6px;
    background-color: #FFF2EA;
    display: flex;
    align-items: center;
}

.text-FF7B2C {
    color: #FF7B2C;
}

.name-ployee-items-confirm {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    margin-bottom: 0;
    color: #FF7B2C;

}

.icon-ployee-items-confirm {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    margin-bottom: 0;
    color: #FF7B2C;
}

.h-100-50px {
    height: calc(100% - 50px);
}