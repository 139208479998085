.custom-card-order-food {
    width: 100%;
    padding: 10px 20px;
}

.visible-hidden {
    visibility: hidden;
}

.order-food-items {
    border: 1px solid #DCDCE4;
    border-radius: 16px;
    padding: 10px 12px;

}

.boxsizing-border-box {
    box-sizing: border-box;
}

.order-food-items-print {
    padding: 10px 12px;
}

.status-items-order {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 30px; */
    padding: 4px 8px 4px 8px;
    border-radius: 8px;
    background-color: #FFF2EA;
    gap: 5px;
}

.text-items-order {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    color: #FF7B2C;
}

.text-items-order-print {
    /* font-family: Roboto; */
    font-size: 12px;
    font-weight: 400;
    color: black;
}

.text-note-information-card-items-print {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.text-items-order-print {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    color: black;
}

.text-black {
    color: black !important;
}

.text-x-print {
    font-family: Mulish;
    font-size: 8px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: black;
}

.btn-number-order {
    background-color: #FFF2EA;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.gap-8px {
    gap: 8px;
}

.text-number-order {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #666687;
}

.text-number-order-print {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: black;
}

.name-food-print {
    color: black;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.text-more-request {
    width: 100%;
    height: 82px;
    padding: 12px 16px 12px 16px;
    border-radius: 16px;
    border: 1px solid #DCDCE4;
    gap: 10px
}

.text-more-request:focus {
    outline: none;
}

.choose-a-side {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
    margin-top: 16px;
}

.text-number {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #C0C0CF;
}

.text-dollar-2 {
    color: #C0C0CF;
    font-size: 9px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
}

.text-price-food {
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    color: #4A4A6A;
}

.gap-2px {
    gap: 2px;
}

.text-price-food-2 {
    /* //styleName: Price/Small; */
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
}

.mb-2px {
    margin-bottom: 2px;
}

.text-content-title {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #666687;
}

.text-4A4A6A {
    color: #4A4A6A !important;
}

.justify-content-end {
    justify-content: end !important
}

.pb-4 {
    padding-bottom: 16px !important;
}

.w-85 {
    width: 85%;
}

.border-primary {
    border: 1px solid #12B064;
}

.text-split-bill {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #12B064;
}

.icon-split-bill {
    font-size: 30px;
    color: #12B064;
}