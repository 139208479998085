.modal-add-side-dish-food > .modal-dialog {
  width: 600px;
  position: absolute;
  right: 100px;
}
.modal-add-side-dish-food > .modal-dialog > .modal-content {
  min-height: 40vh;
  height: 60vh;
}

.body-modal-sidedish {
  height: calc(60vh - 100px);
}
