tbody.body-table-reivew {
    display: table-caption;
    height: calc(100vh - 410px) !important;
    overflow-y: scroll;
    width: 100%;
}

.w-6 {
    width: 6%;
}

.w-14 {
    width: 14%;
}

.text-F24343 {
    color: #F24343;
}

.bg-F243431A {
    background: rgba(242, 67, 67, 0.1);
}

.bg-1E7BCC {
    background-color: #F6F6F9;
}

.text-1E7BCC {
    color: #1E7BCC;
}