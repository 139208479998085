.w-100 .form-check-label {
    width: 100%;
}

.max-height-400px {
    max-height: 400px;
    overflow-y: scroll;
}
.max-height-350px {
    max-height: 350px;
    overflow-y: auto;
}


.add-new-bill-order {
    font-family: SVN-Gilroy;
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
}

.py-2px {
    padding-top: 2px;
    padding-bottom: 2px;
}

.min-height-500px {
    min-height: 500px;
}

.box-add-another-dish {
    padding-top: 12px;
}

.items-dish-choose {
    height: 50px;
    width: 100%;
    background-color: #0FA54A;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}