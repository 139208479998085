.h-body-table-customer {
    display: table-caption;
    height: calc(100vh - 400px) !important;
    overflow-y: scroll;
    width: 100%;
}

.w-35 {
    width: 35%;
}

.w-15 {
    width: 15%;
}

.react-select__value-container.react-select__value-container--has-value.css-g1d714-ValueContainer {
    padding: 0;
}