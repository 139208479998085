.css-2cirb8-MuiPieArc-root {
    fill: #FF7B2C !important;
}

.css-kso0wp-MuiPieArc-root {
    fill: #12B064 !important;
}

.css-ct8a61-MuiPieArc-root {
    fill: #F24343 !important;
}

.w-350px {
    width: 350px;
}

/* .custom-header-table {
    width: calc(100% - 240px);
} */
.custom-flex-header {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

.custom-header-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 12px; */
}

.custom-header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 12px !important;
}

@media only screen and (max-width: 1800px) {
    /* .custom-flex-header {
        display: block;
    }

    .custom-header-left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #DCDCE4;
    }

    .custom-header-right {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        width: 100%;
        border-left: unset !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: unset !important;

    } */
}