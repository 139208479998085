.modal-add-printer .modal-dialog .modal-content {
  border-radius: 22px;
}

.box-input-add-printer-name {
  border: 1px solid rgb(193, 193, 194);
  border-radius: 12px;
  overflow: hidden;
}

.bg-delete-light {
  background-color: rgb(253, 90, 90);
}

.fade.modal-add-printer.modal.show {
  background: #00000080;
}

/* .input-enter-printer-name::placeholder {} */
.form-check-void-bill .form-check-input {
  height: 30px;
  width: 30px;
}
.form-check-void-bill .form-check-label {
  display: flex;
  align-items: center;
  width: 100%;
}
