.link-home {
    /* //styleName: Heading/Heading 5; */
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: #A9A9A9;
}

.text-home-child {
    /* //styleName: Heading/Heading 5; */
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: #0FA54A;
}

.box-number-notifition {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #FF4545;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
    position: absolute;
    top: -5px;
    right: 0px;
}

.box-notification {
    width: 550px;
    max-height: 100vh;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 4px 120px 0px #24242414;
}

.text-footer-nofitication {
    font-family: Roboto, sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: -0.005em;
    text-align: left;
    color: #4A4A6A;
}

.footter-box-notification {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 80px;
    border-top: 2px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    box-shadow: 0px 3px 5px 0px #1011131A;
}

.box-options-notification {
    width: 100%;
    height: 43px;
    border-radius: 12px;
    gap: 4px;
    padding: 5px;
    background-color: #F8F9FA;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-option-notification {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
}

.option-notification {
    width: 160px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
}

.option-notification-2 {
    width: 160px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 3px 5px 0px #1011131A;
    background-color: white;
    border-radius: 4px;
}

.text-notification-delete-2day {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #8E8EA9;
    margin-bottom: 24px;
}

.box-img-notification-items {
    height: 56px;
    width: 56px;
    border-radius: 12px;
    background-color: #E7F7EF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-items-notification {
    width: 100%;
    height: 97px;
    padding: 18px;
    align-items: center;
    display: flex;
    border: 1px solid #F6F6F9;
    border-radius: 12px;
}

.border-DCDCE4 {
    border: 1px solid #DCDCE4 !important;
}

.text-items-notification {
    /* //styleName: Heading/Heading 3; */
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
}

.text-A5A5BA-2 {
    color: #A5A5BA;
}

.text-option-status-note {
    /* //styleName: Heading/Heading 4; */
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #8E8EA9;
}

.new-notification {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: 8px;
}

.height-notification {
    max-height: calc(100vh - 400px);
    overflow-y: scroll;
    padding-right: 4px;
}
.btn-reload-page{
    border: #999999;
    height: 36px;
    padding: 0px 12px;
    border-radius: 8px;
    color: #4a4a6a;
    font-weight: 600;
    font-size: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.mr-300px{
    margin-right: 300px;
}