.h-body-table-order {
    display: table-caption;
    height: calc(100vh - 290px) !important;
    overflow-y: scroll;
    width: 100%;
}

.w-35 {
    width: 35%;
}

.w-15 {
    width: 15%;
}

.react-select__value-container.react-select__value-container--has-value.css-g1d714-ValueContainer {
    padding: 0;
}

.w-12 {
    width: 12%;
}

.w-37 {
    width: 37%;
}

.table-order-owner {
    height: fit-content;
    overflow: hidden;
}