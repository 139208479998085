@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;400&display=swap');

.right-10 {
  right: 40px;
}

.custom-btn-back-page {
  height: 40px;
  width: 60px;
  border-radius: 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.custom-btn-order-more {
  height: 40px;
  /* width: 60px; */
  padding: 0 10px;
  border-radius: 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.h-50px {
  height: 50px;
}

.w-50px {
  width: 50px;
}

.w-60px {
  width: 60px;
}

.bg-red-light {
  background-color: #f6aeae;
}

.w-25px {
  width: 25px;
}

.h-25px {
  height: 25px;
}

.h-3px {
  height: 12px;
}

.fw-600 {
  font-weight: 600;
}

.min-h-100vh {
  min-height: 100vh;
}

.w-3px {
  width: 12px;
}

.min-h-100 {
  min-height: 100%;
}

.height-40px {
  height: 40px;
}

.width-75 {
  width: 75%;
}

.border-input-add-request {
  border: 1px solid rgb(209, 209, 209);
}

.border-gray-light {
  border: 1px solid rgb(209, 209, 209);
}

.border-gray-light:focus-visible {
  outline: none !important;
}

.border-input-add-request:focus-visible {
  outline: none !important;
}

.col-6 .form-check .form-check-input {
  height: 20px;
  width: 20px;
  margin: 4px;

}

.w-30 {
  width: 30%;
}

.col-6 .form-check.form-check-inline {
  display: flex;
  align-items: center;
  padding: 0;
}

.custom-btn-close-add-request {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  top: -4px;
  right: -4px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  border: 1px solid green;
  padding: 2px;
}

.flex-shink-0 {
  flex-shrink: 0;
}

.max-w-220px {
  max-width: 220px;
}

.h-33px {
  height: 33px;
}

.bg-f37070 {
  background-color: #f37070;
}

.custom-scroll-items {
  height: 280px;
  overflow-y: scroll;

}

.input-radio-row .form-check {
  display: flex;
  align-items: center;
  padding: 0;
}

.custom-radio-card .form-check {
  display: flex;
  align-items: center;
  padding: 0;
}

.custom-radio-card .form-check .form-check-input {
  height: 20px;
  width: 20px;
  margin: 4px;

}

.input-radio-row .form-check .form-check-input {
  height: 20px;
  width: 20px;
  margin: 4px;

}

.custom-header-choose-menu {
  padding-top: env(safe-area-inset-top);
  height: 64px;
  width: 100px;
  background-color: #12B064;
  background-image: url(../../assets/images/choose-menu/bg-header.png);
  border-radius: 0px 0px 18px 18px;
  box-shadow: 0px 20px 24px 0px rgba(20, 37, 63, 0.06), 0px 0px 1px 0px rgba(12, 26, 75, 0.10);
  padding: 10px 18px;
  position: fixed;
  z-index: 10;
}

.custom-pading-header {
  padding-top: 64px;
}

.text-header {
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
}

.height-54px {
  height: 54px;
}

.rounded-xl {
  border-radius: 16px;
}

.bg-F6F6F9 {

  background-color: #F6F6F9;
}

.text-8E8EA9 {
  color: #8E8EA9;
}

.focus-none:focus {
  background: none;
}

.rounded-21px {
  border-radius: 21px;
}

.text-filter {
  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.text-4A4A6A {
  color: #4A4A6A;
}

.bg-F6F6F9 {
  background-color: #F6F6F9;
}

.btn-add-food-cart {
  border-radius: 50%;
  background-color: #12B064;
  width: 80px;
  height: 80px;
  position: absolute;
  right: -37px;
  top: -37px;
}

.height-120px {
  height: 120px;
}

.width-120px {
  width: 120px;
}

.btn-icon-plus {
  position: absolute;
  top: 45px;
  left: 11px;
}

.text-name-food {
  color: #4A4A6A;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.text-price-food {
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  color: #FF7B2C;
}

.text-price-food-old {
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #8b8b8b;
}

.text-dollar {
  color: #FFB080;
  font-size: 9px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.text-dollar-old {
  color: #8b8b8b;
  font-size: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.text-FFB080 {
  color: #FFB080;
  font-size: 13px;

}

.gap-2px {
  gap: 2px;
}

.title-name-store {
  color: #FFF;
  font-size: 26px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.5px;
}

.footer-choose-menu {
  border-radius: 24px 24px 0px 0px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.number-cart {
  background-color: red;
  color: white;
  position: absolute;
  aspect-ratio: 1;
  height: 30px;
  width: 30px;
  /* padding: 10px; */
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  top: 0px;
  right: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number-cart-2 {
  background-color: red;
  color: white;
  position: absolute;
  aspect-ratio: 1;
  height: 20px;
  width: 20px;
  /* padding: 10px; */
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  top: 0px;
  right: -5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number-cart-3 {
  background-color: red;
  color: white;
  position: absolute;
  /* padding: 3px; */
  height: 30px;
  width: 30px;
  aspect-ratio: 1/1;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-take-away-dine-in {
  height: 54px;
  border-radius: 16px;
  width: 46%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-DCDCE4 {
  border: 1px solid #DCDCE4;
}

.border-FFB01D {
  border: 1px solid #FFB01D;
}

.font-Roboto {
  font-family: Roboto;
}

.text-take-away-dinner {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}

.header-imformation-order {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 12px;
  height: auto;
  background-color: white;
  box-shadow: 0px 4px 20px -2px rgba(50, 50, 71, 0.08);
  /* box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.1); */
}

.text-review-imformation {
  color: rgba(142, 142, 169, 1);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.text-number-order {
  color: #32324D;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.text-order-more {
  color: #FF7B2C;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}

.padding-top-header-information {
  padding-top: 138px;
  padding-bottom: 150px;
}

.name-food {
  color: #4A4A6A;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.text-choose-a-side {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.text-666687 {
  color: #666687;
}

.text-12B064 {
  color: #12B064;
}

.text-note-information-card-items {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #A5A5BA;
}

.custom-filter-btn {
  border-radius: 16px;
  background: #12B064;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  text-align: center;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.text-ffffff {
  color: white;
}

.text-price-information-card {
  color: #FF7B2C;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.text-total-information-card {
  color: #4A4A6A;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}

.pb-footer {
  padding-bottom: 120px;
  height: auto;
}

.we-got-you-order {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #8E8EA9;
}

.we-start-to {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #24A44B;
  /* padding: 0px 48px; */
}

.focus-visible-none:focus-visible {
  border: none !important;
  outline: none !important;
}

.focus-visible-none:focus {
  border: none !important;
  outline: none !important;
}

.accordion-button:focus {
  border-color: transparent !important;
  outline: none !important;
}

.text-order-list {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: #4A4A6A;
}

.text-order-list .accordion-button {
  padding: 0px 6px 0px 0px !important;
  /* padding: 0 !important; */
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #666687;
  margin-bottom: 10px;
}

.text-order-list .accordion-button::after {
  background-image: url('../../assets/images/choose-menu/chevron-up.svg') !important;
}

.accordion-button:not(.collapsed) {
  color: #4A4A6A !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.text-dollar-3 {
  font-family: Mulish;
  font-size: 8px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #8E8EA9;
}

.text-price-food-3 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #4A4A6A;
}

.name-title-price {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #666687;
}

.text-x {
  font-family: Mulish;
  font-size: 8px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #C0C0CF;
}

button:focus:not(:focus-visible) {
  box-shadow: none !important;

}

.box-input-voucher {
  padding: 12px 8px 12px 16px;
  border-radius: 16px;
  border: dashed 1px #FF7B2C;
  gap: 10px;
  display: flex;
  justify-content: space-between;
}

.input-voucher {
  /* //styleName: Input/Placeholder/Default; */
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #8E8EA9;
  border: none;
}

.input-voucher::placeholder {
  color: #8E8EA9;
}

.btn-apply-voucher {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  padding: 5px 28px;
  border-radius: 12px;
  gap: 8px;
  background-color: #FF7B2C;
  color: white;
  border: none;
}

.text-price-mybill {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #FF7B2C;

}

.w-30 {
  width: 30%;
}

.custom-choose-tip {
  background-color: white;
  border: 1px solid #DCDCE4;
  border-radius: 16px;
  /* padding: 12px; */
  height: 46px;
}

.custom-choose-tip-2 {
  background-color: #FFB01D;
  border: 1px solid #FFB01D;
  border-radius: 16px;
  /* padding: 12px; */
  height: 46px;
}

.text-choose-tip {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}

.rounded-16px {
  border-radius: 16px;
  padding: 13px 16px;
}

.border-1px-gray {
  border: 1px solid #EAEAEF;
}

.text-other {
  /* //styleName: Button/Medium; */
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #A5A5BA;
}

.payment-method {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #12B064;
}

.mr-6px {
  margin-right: 6px;
}

.text-content-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #666687;
}

.bg-wait-screen {
  background-image: url('../../assets/images/web-checkin/bg-wait-screen.png');
}

.name-store {
  font-family: Roboto;
  font-size: 40px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.5px;
  text-align: left;
}

.text-8E8EA9 {
  color: #8E8EA9;
}

.custom-btn-payment-status {
  border-radius: 16px;
  background: white;
  display: flex;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  text-align: center;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.border-1-8E8EA9 {
  border: 1px solid #8E8EA9;
}

.text-payment {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;

}

.text-payment-status {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.5px;
  text-align: center;
}

.text-24A44B {
  color: #24A44B;
}

.text-CB0E0E {
  color: #CB0E0E;
}

.text-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.text-content {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.bg-F6F9F8 {
  background-color: #F6F9F8;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
}

.text-subtitle {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;

}

.height-49px {
  height: 49px;
}

.width-30px {
  width: 30px;
}

.focus-visible-gray:focus-visible {
  border: 1px solid #989898;
  outline: none;
}

.focus-visible-gray:focus {
  outline: none;
}

.rounded-6px {
  border-radius: 6px;
}

.btn-clear-search-food {
  width: 150px;
  height: 50px;
  border-radius: 8px;
  background-color: #12B064;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  color: white;
  display: flex;
  gap: 10px;
}

.box-payment-success {
  border: 1px solid #12B064;
  border-radius: 16px;
  padding: 20px;
}

.text-notification-payment-success {
  color: #12B064;
  font-size: 16px;
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.custom-h-my-bill {
  height: calc(100vh - 143px);
  overflow-y: scroll;
}

.sidebar__logo {
  height: 131px;
}

.btn-back-privacy-policy {
  font-size: 34px !important;
  color: #12B064;
}

.box-btn-back-privacy-policy {
  height: 50px;
  border: 1px solid;
  padding: 0px 16px 0px 16px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  border: 1px solid #12B064;
}

.w-180px {
  width: 180px;
}

.tooltip-payment-web-checkin .MuiTooltip-popper {
  width: calc(100vw - 150px);
  z-index: 10 !important;
}

.text-blue {
  color: #055fef;
}

.custom-side-dish-btn {
  border-radius: 8px;
  background: #12B064;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  text-align: center;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  max-width: 180px;
}

.shadow-header {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.text-side-dish {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.box-menu-in-phone {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.mt-10px {
  margin-top: 10px;
}

.text-orange {
  color: #FF7B2C;
}

.w-1-3 {
  width: calc(100% / 3);
}

.table-order-history-check-in {
  overflow-y: auto;
  height: calc(100vh - 120px);

}
.box-food-hisotry{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.table-order-history-check-in thead tr th {
  position: sticky;
  top: 0px;
}

table {
  border-collapse: collapse;
  /* make the table borders collapse to each other */
  width: 100%;
}


.table-order-history-check-in th {
  background: #00A15A !important;
}

.text-36px {
  font-size: 36px !important;
}

.box-list-game {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 20px 0px;
}

.border-white-4px {
  border: 4px solid white;
}

.border-orange-4px {
  border: 4px solid #FF7B2C;
}

.icon-play-game {
  font-size: 45px !important;
}

.history-order {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}