.shadow-choose-status-order {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.w-224px {
  width: 224px;
}

.h-56px {
  height: 56px;
}

.text-more-request2 {
  width: 100%;
  height: 140px;
  padding: 12px 16px 12px 16px;
  border-radius: 16px;
  border: 1px solid #DCDCE4;
  gap: 10px
}

.text-more-request2:focus {
  outline: none;
}

.pb-70px {
  padding-bottom: 70px;
}

.bg-orange {
  background-color: #FE841B;
}