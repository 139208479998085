.step-number {
  font-weight: bold;
  color: #4a4a6a;
  font-size: 18px;
  margin: 12px 0px 0px 0px;
}
.border-999999 {
  border: 1px solid #999999;
}
input:focus-visible {
  outline: none;
}

.text-send-gift-card {
  font-weight: 600;
  color: #4a4a6a;
  font-size: 16px;
  margin: 0;
}

.btn-submit-gift-card {
  width: 150px;
  height: 40px;
  font-weight: 700;
  border: none;
  font-size: 15px;
  color: white;
  border-radius: 8px;
}
.btn-submit-gift-card:nth-child(1) {
  background-color: rgb(248, 109, 109);
}
.btn-submit-gift-card:nth-child(2) {
  background-color: rgb(248, 159, 108);
}
.btn-submit-gift-card:nth-child(3) {
  background-color: rgb(24, 131, 56);
}

.modal-byt-gift-card .modal-dialog {
  max-width: 600px !important;
  width: 600px !important;
}
