@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Unbounded:wght@200;300;400;500;600;700;800;900&display=swap');
@import url("./pages/webowner/components/QRCodeItems/Styles.css");
@import url("./pages/webowner/Styles.css");
@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Edu+TAS+Beginner&family=Poppins:wght@200&display=swap');

.right-10 {
    right: 40px;
}

.bg-gray-light {
    background-color: #ececec;
}

.h-fit {
    height: fit-content;
}

.mt-1 {
    margin-top: 4px;
}

.padding-1 {
    padding: 4px !important;
}

.react-select__control.css-yk16xz-control {
    height: 40px;
}

.font-bold {
    font-weight: 800 !important;
}

.text-sm {
    font-size: 14px;
}

.text-base {
    font-size: 16px;
}

.text-xl {
    font-size: 20px;
}

.text-2xl {
    font-size: 24px;
}

.text-3xl {
    font-size: 26px;
}

.text-black {
    color: #11151f !important;
}

.text-capitalize {
    text-transform: capitalize;
}

.pr-0 {
    padding-right: 0;
}

.pl-0 {
    padding-left: 0;
}

.pr-4 {
    padding-right: 16px;
}

.px-4 {
    padding-right: 16px;
    padding-left: 16px;
}

.pt-10 {
    padding-top: 40px;
}

.w-200px {
    width: 200px;
}

.height-100 {
    height: 100%;
}

.h-130px {
    height: 130px;
}

.h-40px {
    height: 40px;
}

.w-130px {
    width: 130px;
}

.w-fit {
    width: fit-content;
}

.w-100px {
    width: 100px;
}

.w-50px {
    width: 50px;
}

.h-100-130px {
    height: calc(100% - 130px);
}

.h-30px {
    height: 30px;
}

.text-green {
    color: #12B064;
}

.text-white {
    color: white;
}

.border-1px-primary {
    border: 1px solid #12B064;
}

.chip {
    background: #12B064 !important;
}

.highlightOption {
    background: #12B064 !important;
}

.flex-shink-0 {
    flex-shrink: 0;
}

.-left-2 {
    left: 0;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.hidden-scrollbar::-webkit-scrollbar {
    display: none;
}

.text-11151f {
    color: #11151f;
}

.border-none {
    border: none;
}

.font-medium {
    font-weight: 500;
}

.mt-18px {
    margin-top: 18px;
}

.h-36px {
    height: 36px;
}

.text-xs {
    font-size: 12px;
}

.h-20px {
    height: 20px;
}

.w-20px {
    width: 20px;
}

.padding-top-4px {
    padding-top: 4px;
}

.-right-2 {
    right: 0;
}

@media only screen and (max-width: 425px) {
    .col-425-100 {
        width: 100% !important;
    }

    .mt-425-3 {
        margin-top: 12px;
    }
}

.rhap_main.rhap_stacked {
    background-color: #EFF3F1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.w-50 {
    width: 50%;
}

.notification-price-payment {
    border: 1px solid #DCDCE4;
    height: 88px;
    border-radius: 12px;
}

.d-flex {
    display: flex;
}

.pagebreak {
    break-after: always !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;
    page-break-before: always !important;
}

p {
    font-family: 'Roboto', sans-serif !important;
}

.brake-page {
    page-break-before: always !important;
    page-break-inside: avoid !important;
}

iframe#webpack-dev-server-client-overlay {
    display: none !important
}

.touch-action {
    touch-action: auto;
    cursor: move;
}

.text-title-chart-report {
    color: #4a4a6a;
    font-size: 26px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    margin-bottom: -40px;
    text-align: center;
}

.box-lable-chart-line {
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

.text-lable-legend {
    color: #4a4a6a;
    font-size: 14x;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0px;
    text-align: center;
}

.w-170px {
    width: 150px;
}

.w-49 {
    width: 49%;
}

.Popup-Printer {
    position: fixed;
    left: 50%;
    top: 40px;
    width: 70vw;
    display: flex;
    justify-content: center;
    gap: 15px;
    z-index: 9999;
    transform: translate(-50%, -50%);
}

.content-popup-printer {
    padding: 12px 16px;
    border-radius: 12px;
    color: red;
    border: 1px solid red;
    background: white;
}

/* hidden scroll bar */
.hidden-scroll-bar::-webkit-scrollbar {
    display: none !important;
}

.hidden-scroll-bar {
    -ms-overflow-style: none !important;
    /* IE and Edge */
    scrollbar-width: none !important;
    /* Firefox */
}

body::-webkit-scrollbar,
div::-webkit-scrollbar {
    display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
body::-webkit-scrollbar,
div::-webkit-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}