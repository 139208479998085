/* tr{
    display: table;
} */
.w-40 {
    width: 40%;
}

.w-17 {
    width: 17%;
}

.text-content-table-clock {
    /* //styleName: Subtitle/Subtitle 1; */
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
}

.text-time-table-clock {
    /* //styleName: Body/Body 1; */
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
}

.text-228BE6 {
    color: #228BE6 !important;
}

.font-500 {
    font-weight: 500 !important;
}

.w-33 {
    width: 33%;
}

.text-black {
    color: black !important;
}