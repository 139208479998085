.text-fd7e14 {
  color: #fd7e14;
}

.bg-orange-light {
  background-color: #fce4d0;
}

.shadow-items-equiment {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.border-choose-item-laneding {
  border: 10px outset #fd7e14;
  border-radius: 24px;
  overflow: hidden;
}

.border-normal-item-laneding {
  border: 10px outset white;
  border-radius: 24px;
  overflow: hidden;
}