.modal-add-printer .modal-dialog .modal-content {
  border-radius: 22px;
}

.box-input-add-printer-name {
  border: 1px solid rgb(193, 193, 194);
  border-radius: 12px;
  overflow: hidden;
}

.input-enter-printer-name::placeholder {}

.input-check-add-printer {
  width: 20px;
  height: 20px;
  appearance: none;
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}

.input-check-add-printer:checked {
  background-color: green;
}

.input-check-add-printer:checked::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: green;
}

.title-select-option {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.title-ques-add-printer {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}